.t-home {
  .panels {
    display: flex;
    width: 100%;
    min-height: 100vh;
    overflow-y: hidden;
    flex-direction: column;

    @include screen-tablet-landscape-and-bigger {
      flex-direction: row;
    }
  }
}

.animation-panel {
  // height: 50vh;
  aspect-ratio: 5/4;
  background-color: $color-black;

  @include screen-tablet-landscape-and-bigger {
    width: calc(50% - 10px);
    height: auto;
  }

  .dept-link {
    position: fixed;
    z-index: 3;
    bottom: 15px;
    left: 20px;
    color: $color-grey;

    &:hover {
      color: $color-white;
    }
  }

  .sd-animation {
    width: 100%;
    height: 100%;
    position: relative;

    svg {
      left: 0;
      top: 0;
      position: absolute;

      path {
        fill: #fff;
      }
    }

    .animation__s {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      animation: letter_total_s 18s linear infinite;

      @keyframes letter_total_s {
        0%,
        50% {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }

        24.999% {
          width: 0%;
          height: 100%;
          left: 0;
          top: 0;
        }

        25% {
          width: 100%;
          height: 0%;
          left: 0;
          top: 0;
        }

        74.999% {
          width: 0%;
          height: 100%;
          left: 100%;
          top: 0;
        }

        75% {
          width: 100%;
          height: 0%;
          left: 0;
          top: 100%;
        }
      }
    }

    .animation__d {
      width: 0%;
      height: 100%;
      left: 100%;
      top: 0;
      animation: letter_total_d 18s linear infinite;

      @keyframes letter_total_d {
        0% {
          width: 0%;
          height: 100%;
          left: 100%;
          top: 0;
        }

        25%,
        75% {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }

        49.999% {
          width: 100%;
          height: 0%;
          left: 0;
          top: 100%;
        }

        50% {
          width: 0%;
          height: 100%;
          left: 0;
          top: 0;
        }

        99.999% {
          width: 100%;
          height: 0%;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

// —————————————————————————————————————————————————————————————————————————————

section.projects {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  opacity:1;
  cursor: default;
  // COMMENT OR NO COMMENT
  // transition: transform 0.2s $ease-in-out-quart;
  
  &.imageTransitioning {
    transform: scale(0.85);
  }
  .project {
    position: absolute;
    // height: 100vh;
    width: 100%;
    top: 0px;
    left: 0px;
    cursor: default;
    // COMMENT OR NO COMMENT
    // transition: transform 0.2s $ease-in-out-quart;
    
    .project__bg {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    figure {
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      overflow: hidden;
      // COMMENT OR NO COMMENT
      // transition: all 0.2s $ease-in-out-quart;
      
      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        overflow: visible;
      }
      figcaption {
        display: none;
      }
    }
    opacity: 0;
    pointer-events: none;
    &.imageActive {
      opacity: 1;
      // pointer-events: all;
      &.imageTransitioning {
        transform: scale(0.8);
      }
    }

    &.projectActive{
      pointer-events: all;
    }

    span {
      position: absolute;
      bottom: 12px;
      right: 10px;
      mix-blend-mode: difference;
      text-align: right;
      
      > span {
        right: 0;
        bottom: 0;
        white-space: pre;
        text-align: right;
        a:hover{
          color: #868686;
        }
      }

// ————————

      // position: absolute;
      // bottom: 50vh;
      // left: 50vw;
      // mix-blend-mode: difference;
      // text-align: center;
      // width: 0;
      // height: 0;
      // line-height: 0;
      // user-select: none;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // white-space: pre;
      // @include fluid-type($font-size-h1, $font-size-h1*0.4);

    }
  }
  &.galleryActive {
    pointer-events: none;

    // & + .mouse-follower {
    //   display: none !important;
    // }
  }
}

.mouse-follower {
  display: none;
}

// section.projects {
//   width: 100vw;
//   display: flex;
//   flex-direction: column;
//   .project {
//     height: 120vh;
//     cursor: none;
//     .project__bg {
//       width: 100%;
//       height: 100%;
//       background-repeat: no-repeat;
//       background-position: center;
//       background-size: cover;
//     }
//     figure {
//       height: 100%;
//       width: auto;
//       margin-bottom: 0;
//       video {
//         height: 100%;
//         width: 100%;
//         object-fit: cover;
//       }
//     }
//     &:first-of-type, &:last-of-type {
//       height: 100vh;
//     }
//   }
//   .mouse-follower {
//     position: fixed;
//     cursor: none;
//     mix-blend-mode: difference;
//   }
// }

// —————————————————————————————————————————————————————————————————————————————

.projects-panel {
  display: none;

  @include screen-tablet-landscape-and-bigger {
    width: calc(50% + 10px);
    display: block;
  }

  .projects {
    width: 100%;
    height: 100%;
    position: relative;

    .project {
      display: block;
      width: 100%;
      height: 100%;
      //transition: top 0.4s $ease-out-quart, opacity 0.4s $ease-out-quart;

      @include screen-tablet-landscape-and-bigger {
        position: absolute;
        //top: -20px;
        left: 0;
        display:none;

        &.active {
          //top: 0;
          display:block;
          opacity: 1;
        }
      }

      &.projectActive{
        pointer-events: all;
      }

      .project__bg {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        min-height: 20rem;
        background-position: 50%;
        background-size: cover;
        background-repeat: no-repeat;

        @include screen-tablet-landscape-and-bigger {
          position: absolute;
        }
      }
    }
  }
}

.projects-panel-mobile {
  figure {
    margin-bottom: 0;
  }

  figcaption {
    display: none;
  }

  @include screen-tablet-landscape-and-bigger {
    display: none;
  }
}
