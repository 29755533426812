/*----------------------------------*\
  #TYPOGRAPHY
\*----------------------------------*/


// Font stacks

$font-stack-primary   : 'Haas', Arial, sans-serif;
$font-stack-secondary : 'HaasDisplay', sans-serif;


// Font settings

$font-stack-body      : $font-stack-primary;

// Font sizes

$font-size-root       : 10px;       // The font size set on the root html element.
$font-size-root-mobile: 7.5px; 

$font-size-body       : 2rem;       // Regular font size

$font-size-large      : 3rem;       // Small font size

$font-size-xtralarge  : 4rem;     

$font-size-huge       : 20rem;  

$font-size-h1         : 8rem;

$font-size-h2         : 6rem;

$font-size-h3         : 4.5rem;

$font-size-h4         : 1.6rem;

$font-size-h5         : 1.3rem;

$font-size-h6         : 1.1rem;


// Font line heights
// Divide the the given line height by the font size to give an unitless line height
// @example {design line height} / {font size} = {unitless lineheight}
//
// NOTE: Never use px as line-height value!!!

$font-line-height-body: 1.2; // {design-line-height} / strip-unit($font-size-body);

$font-line-height-large: 1.3; // {design-line-height} / strip-unit($font-size-body);

$font-line-height-h1:   1.05; // {design-line-height} / strip-unit($font-size-h1);

$font-line-height-h2:   1.1; // {design-line-height} / strip-unit($font-size-h2);

$font-line-height-h3:   1.3; // {design-line-height} / strip-unit($font-size-h3);

$font-line-height-h4:   1.5; // {design-line-height} / strip-unit($font-size-h4);

$font-line-height-h5:   1.5; // {design-line-height} / strip-unit($font-size-h5);

$font-line-height-h6:   1.5; // {design-line-height} / strip-unit($font-size-h6);


// Font weights
$font-weight-ultralight : 100;

$font-weight-light      : 200;

$font-weight-book       : 300;

$font-weight-regular    : 400;

$font-weight-medium     : 500;

$font-weight-bold       : 600;

$font-weight-extrabold  : 700;

$font-weight-black      : 800;

$font-weight-super      : 600;
