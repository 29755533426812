.c-footer {
  position: relative;
  z-index: 1;

  .theme-dark & {
    background-color: $color-dark;

    a {
      color: $color-grey;

      &.active,
      &:hover {
        color: $color-white;
      }
    }
  }

  .theme-darkgrey & {
    background-color: $color-off-black;

    a {
      color: $color-grey;

      &.active,
      &:hover {
        color: $color-white;
      }
    }
  }

  .theme-light & {
    background-color: $color-white;

    a {
      color: $color-grey;

      &.active,
      &:hover {
        color: $color-black;
      }
    }
  }
}


.c-footer {

  &.footer--project {
    background: $color-dark;
    position: relative;
    z-index: 2;
    padding-top: 40px;

    @include screen-tablet-landscape-and-bigger {
      padding-top: 160px;
    }

    .theme-light & {
      background: $color-white;
    }
  }

  .welcome-everyone {
    p {
      color: $color-white;
      line-height: 1.2;
      @include fluid-type($font-size-xtralarge, $font-size-xtralarge*0.625);
      
      margin-bottom: $gutter-vertical-micro*6;
      @include screen-tablet-landscape-and-bigger {
        margin-bottom: $gutter-vertical-big;
      }

      a {
        color: $color-grey;
        white-space: nowrap;
        transition: color 0s;

        &:hover {
          color: $color-white;
        }
      }
    }
  }
  .footer__footer {
    p {
      color: $color-grey;
      margin-bottom: 1.6rem;
      @include fluid-type($font-size-body, $font-size-body * 0.75);

      a {
        color: $color-grey;
      }

      strong {
        color: $color-white;
      }
    }
    nav {
      display: none;
      @include screen-tablet-landscape-and-bigger {
        display: flex;
        justify-content: space-between;
      }

      ul {
        list-style: none;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
      }
    }
  }

  .theme-light & {
    .welcome-everyone {
      p {
        color: $color-black;

        a {
          color: $color-grey;

          &:hover {
            color: $color-black;
          }
        }
      }
    }
    .footer__footer {
      p {
        a {
          color: $color-grey;

          &:hover {
            color: $color-black;
          }
        }

        strong {
          color: $color-black;
        }
      }
    }
  }
}

