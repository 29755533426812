.project__wrapper {
    overflow: hidden;
}

// .temp-project{
//     header{

//         nav:not(.mobile-menu) ul a {
//             color: $color-white;
//             &:hover{
//                 color: $color-grey;
//             }
//         }

//     }
// }

.hero {
    width: 100%;
    top: 0;
    position: sticky;
    overflow: hidden;
    z-index: 1;
    aspect-ratio: 4/5;

    &.header-landscape-mobile {
        aspect-ratio: 16/9;
        height: auto;
        max-height: 100vh;
    }

	&.header-landscape-mobile-inbo-special{
		aspect-ratio: 4/5 !important;
		@include screen-tablet-landscape-and-bigger {
			aspect-ratio: 16/9  !important;
		}
	}

    @include screen-tablet-portrait-and-bigger {
        aspect-ratio: auto;
        height: 75vh;
    }
    
    @include screen-tablet-landscape-and-bigger {
        aspect-ratio: auto;
        height: 100vh;
    }

    .hero__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        min-height: 20rem;
        object-fit: cover;
    }

    .figure-image {
        margin-bottom: 0;
        height:100%;

        img{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include screen-tablet-landscape-and-bigger {
            img {
                height: 100vh;
                object-fit: cover;

            }
        }
    }

    figcaption {
        display: none;
    }

    .figure-video {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        height:100%;

        @include screen-tablet-landscape-and-bigger {
            display:block;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        video {
            flex-shrink:0;
            height:100%;
            width:auto;
            max-width:none;

            @include screen-tablet-landscape-and-bigger {
                min-width: 100%;
                min-height: 100%;
                width: auto;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &.hero--static-width{
        .figure-video {
            video{
                @include screen-tablet-landscape-and-bigger {
                    min-height:100%;
                    width:100%;
                    object-fit: cover;
                }
            }
        }
    }

    &.hero--special {
        .special__label {
            position: absolute;
            right: 0;
            bottom: 20px;
            width: 60%;
            line-height: 0;

            @include screen-tablet-landscape-and-bigger {
                width: 500px;
                bottom: 40px; 
            }

            @include screen-desktop-and-bigger {
                width: 648px;
                bottom: 50px;
            }

            svg {
                width: 100%;
            }
        }

        &.ps-01 {
            .hero__bg {
                border: 20px solid #f8ce46;

                @include screen-tablet-landscape-and-bigger {
                    border: 40px solid #f8ce46;
                }

                @include screen-desktop-and-bigger {
                    border: 50px solid #f8ce46;
                }
            }

            .special__label svg {
                rect {
                    fill: #6fbad7;
                }
            }
        }

        &.ps-02 {
            .hero__bg {
                border: 20px solid #e69bae;

                @include screen-tablet-landscape-and-bigger {
                    border: 40px solid #e69bae;
                }

                @include screen-desktop-and-bigger {
                    border: 50px solid #e69bae;
                }
            }

            .special__label svg {
                rect {
                    fill: #a9c6d5;
                }
            }
        }

        &.ps-03 {
            .hero__bg {
                border: 20px solid #4198d3;

                @include screen-tablet-landscape-and-bigger {
                    border: 40px solid #4198d3;
                }

                @include screen-desktop-and-bigger {
                    border: 50px solid #4198d3;
                }
            }

            .special__label svg {
                rect {
                    fill: #e79bae;
                }
            }
        }

        &.ps-04 {
            .hero__bg {
                border: 20px solid #d3d3d1;

                @include screen-tablet-landscape-and-bigger {
                    border: 40px solid #d3d3d1;
                }

                @include screen-desktop-and-bigger {
                    border: 50px solid #d3d3d1;
                }
            }

            .special__label svg {
                rect {
                    fill: #f8ce46;
                }
            }
        }

        &.ps-05 {
            .hero__bg {
                border: 20px solid #978a5d;

                @include screen-tablet-landscape-and-bigger {
                    border: 40px solid #978a5d;
                }
                @include screen-laptop-and-bigger {
                    border: 50px solid #978a5d;
                }
            }

            .special__label svg {
                rect {
                    fill: #d3d3d1;
                }
            }
        }
    }
}

// .hero__placeholder {
//     width: 100%;
//     height: 100%;
//     display: flex;
//     aspect-ratio: 4/5;
//     @include screen-tablet-portrait-and-bigger {
//         aspect-ratio: auto;
//         height: 75vh;
//     }
    
//     @include screen-tablet-landscape-and-bigger {
//         aspect-ratio: auto;
//         height: 100vh;
//     }
// }

.t-project {
    width: 100%;
    position: relative;
    z-index: 2;

    .t-project-wrapper {
        background-color: $color-black;
        padding-top: $gutter-vertical-micro*1.5;
        
        .theme-light & {
            background-color: $color-white;
        }
    
        .theme-darkgrey & {
            background-color: $color-off-black;
        }
    }


    .project__meta {
        display: none;
        @include screen-tablet-landscape-and-bigger {
            display: flex;
        }
    }

    .project__specs {
        margin-bottom: 10px;
        display: flex;
        
        @include screen-tablet-landscape-and-bigger {
            margin-bottom: $gutter-vertical-micro*1.5;
        }
    }
    
    .spec {
        span {
            margin-right: $gutter-vertical-micro*1.5;
            @include screen-laptop-and-bigger {
                margin-right: $gutter-vertical-regular;
            }
            display: inline-block;
        }
    }

    .project__main-image {
        figure {
            margin-bottom: $gutter-vertical-micro*6;
            @include screen-tablet-landscape-and-bigger {
                margin-bottom: $gutter-vertical-micro*1.5;
            }
        }
    }

    .project__navigation {
        margin-bottom: 10px;

        @include screen-tablet-landscape-and-bigger {
            margin-bottom: 0;
        }

        a {
            margin-right: 40px;

            svg {
                margin-right: 10px;
            }
        }
    }


    .project__content {

        .project__info {
            .project__info-text {
                display: none;
                @include screen-tablet-landscape-and-bigger {
                    display: flex;
                }
                &.active {
                    display: block;
                }
            }
            .project__intro {
                margin-bottom: $gutter-vertical-micro*3;

                @include screen-tablet-landscape-and-bigger {
                    margin-bottom: 0;
                    padding-bottom: $gutter-vertical-bigger;
                }

                p {
                    @include fluid-type($font-size-large, $font-size-body);
                    line-height: 1.2;
                    margin: 0;
                }
            }
        }

        .read-more {
            color: $color-grey;
            margin-bottom: $gutter-vertical-micro*6;
            transition: color .0s linear;
            cursor: pointer;
            &:hover {
                color: $color-white;
                .theme-light & {
                  color: $color-black;
                }
            }
            @include screen-tablet-landscape-and-bigger {
                display: none;
            }
        }

        .project__full-text {
            margin-bottom: $gutter-vertical-micro*3;
            @include screen-tablet-landscape-and-bigger {
                margin-bottom: $gutter-vertical-micro*6;
            }

            p {
                font-size: $font-size-body;
                line-height: 1.25;
            }
            h3 {
                font-size: $font-size-body;
                margin-bottom: $gutter-vertical-micro;
            }
            p:last-of-type {
                margin: 0;
            }

            &:last-of-type {
                margin-bottom: $gutter-vertical-micro*6;
                @include screen-tablet-landscape-and-bigger {
                    margin-bottom: $gutter-vertical-bigger;
                }
            }
            a {
                color: $color-grey;
                transition: color 0s linear;
                &:hover {
                    color: $color-white;
                }
            }
        }

        .vimeo-container {
            margin-bottom: $gutter-vertical-micro*6;
            width: 100%;
            position:relative;

            @include screen-tablet-landscape-and-bigger {
                margin-bottom: $gutter-vertical-small;
            }

            &.vimeo--extra-margin {
                margin-bottom: 40px;

                @include screen-tablet-landscape-and-bigger {
                    margin-bottom: 100px;
                }
            }

            .vimeo {
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
                max-width: 100%;

                iframe,
                object,
                embed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                }
            }
        }


    }

    .project__main {

        .vimeo-container {
            margin-bottom: 15px;
            width: 100%;
            position:relative;

            @include screen-tablet-landscape-and-bigger {
                margin-bottom: 20px;
            }

            &.vimeo--extra-margin {
                margin-bottom: 40px;

                @include screen-tablet-landscape-and-bigger {
                    margin-bottom: 100px;
                }
            }

            .vimeo {
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
                max-width: 100%;

                iframe,
                object,
                embed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                }
            }
        }

        .figure-image {
            position:relative;

            figcaption {
                @include screen-tablet-landscape-and-bigger {
                    position:absolute;
                    left:calc(50% + 10px);
                }
            }
        }
    }

    .project__text_block {

        display: flex;
        text-align: center;
        justify-content: center;
        padding: 160px 80px;
        @include screen-tablet-landscape-and-bigger {
            padding: 280px 0px;
        }
        .text_block {
            max-width: 580px;
        }

        &.tb-half-padding-top{
            padding: 80px 80px 160px;
            @include screen-tablet-landscape-and-bigger {
                padding: 140px 0px 280px;
            }
        }

        &.tb-half-padding-bottom{
            padding: 160px 80px 80px;
            @include screen-tablet-landscape-and-bigger {
                padding: 280px 0px 140px;
            }
        }

    }

    .project__external {
        background: $color-off-white;
        margin-bottom: 40px;
        display: flex;

        @include screen-tablet-landscape-and-bigger {
            margin-bottom: 100px;
        }

        h3 {
            @include fluid-type($font-size-large, $font-size-large * 0.6);
            color: $color-grey-dark;
            margin-bottom: 15px;
        }

        p {
            color: $color-black;
            @include fluid-type($font-size-body, $font-size-body * 0.75);
        }

        .external__info {
            position: relative;

            @include screen-tablet-landscape-and-bigger {
                width: 50%;
            }

            & > .external__link {
                display: none;

                @include screen-tablet-landscape-and-bigger {
                    display: block;
                }
            }
        }

        .external__image {
            display: none;
            width: 50%;

            @include screen-tablet-landscape-and-bigger {
                display: block;
            }
        }

        .external__link {
            @include fluid-type($font-size-body, $font-size-body * 0.75);
            color: $color-grey;

            @include screen-tablet-landscape-and-bigger {
                position: absolute;
                bottom: 25px;
                left: 25px;
            }

            &:hover {
                color: $color-black;
            }
        }

        figure {
            margin-bottom: 0;
        }

        .external__text {
            padding: 15px;

            @include screen-tablet-landscape-and-bigger {
                padding: 25px;
            }

            a {
                display: inline;
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                    color:$color-white;
                }

                @include screen-tablet-landscape-and-bigger {
                    display: none;
                }
            }
        }
    }

    .project__highlight {
        background: $color-dark;
        margin-bottom: 25px;

        @include screen-tablet-landscape-and-bigger {
            margin-bottom: 80px;
        }

        p {
            @include fluid-type($font-size-body, $font-size-body * 0.75);

            &:last-of-type {
                margin-bottom: 15px;

                @include screen-tablet-landscape-and-bigger {
                    margin-bottom: 40px;
                }
            }
        }

        .theme-light & {
            background: $color-off-white;
        }
    }

    .project__external-links {
        padding: 80px 0 100px;
        margin-bottom: 100px;

        h3 {
            @include fluid-type($font-size-large, $font-size-large * 0.6);
            line-height: $font-line-height-large;
            margin: 0 0 20px;
        }

        .el__links {
            padding-left: 10px;

            a {
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                color: $color-grey-dark;

                &:last-child {
                    margin-bottom: 0;
                }

                svg {
                    display: block;
                    width: 40px;
                    margin-right: 40px;

                    g {
                        fill: $color-grey-dark;
                    }
                }

                &:hover {
                    color: $color-white;

                    svg {
                        g {
                            fill: $color-white;
                        }
                    }
                }
            }
        }

        .el__podcast {
            color: $color-grey-dark;

            h3 {
                @include fluid-type($font-size-large, $font-size-large * 0.6);
                line-height: $font-line-height-large;
                margin: 0 0 20px;
            }

            p {
                margin-bottom: 50px;
            }

            strong {
                color: $color-white;
            }

            a {
                text-decoration: underline;

                svg {
                    margin-left: 20px;

                    g {
                        fill: $color-grey;
                    }
                }
            }
        }

        .theme-light & {
            .el__podcast {
                strong {
                    color: $color-black;
                }
            }
        }
    }

    .project__related {
        padding: 0;
        h3 {
            margin: 0;
            @include fluid-type($font-size-large, $font-size-body);
        }
        .no-margin-wrapper {
            flex-direction: column;
            overflow: overlay;
            flex-wrap: wrap;
            width: 100vw;

            padding-top: $gutter-vertical-bigger;
            padding-bottom: $gutter-vertical-bigger;
            @include screen-tablet-landscape-and-bigger {
                padding-top: $gutter-vertical-insane;
                padding-bottom: $gutter-vertical-insane;
            }

            @include screen-tablet-landscape-and-bigger {
                flex-direction: row;
                flex-wrap: nowrap;
                width: auto;
            }
            background-color: $color-black;
            .theme-light & {
                background-color: $color-white;
            }
            .theme-darkgrey & {
                background-color: $color-off-black;
            }
        }

        .related__title {
            position: absolute;
            padding-left: $gutter-vertical-micro*1.5;

            @include screen-laptop-and-bigger {
                position: relative;
                display: flex;
                align-items: flex-start;
            }

            a {
                display: none;

                @include screen-tablet-landscape-and-bigger {
                    color:$color-grey;
                    display: inline-block;

                    &:hover {
                        color:$color-white;
                    }
                }
            }
        }
        .related-projects__wrapper {
            display: flex;
            overflow: overlay;
            flex-direction: row;
            padding: 0 $gutter-vertical-micro;
            margin-top: $gutter-vertical-micro*8;
            @include screen-laptop-and-bigger {
                margin-top: 0;
            }
            .related-project {
                .figure-image, .figure-video {
                    margin-bottom: 0;
                    @include screen-tablet-landscape-and-bigger {
                        margin-bottom: 0;
                    }
                    picture, video {
                        display: block;
                        aspect-ratio: 4/5;
                    }
                }
    
                figcaption,
                .figcaption-name {
                    color: $color-grey;
                }
    
                &:hover {
                    figcaption,
                    .figcaption-name {
                        color: $color-white;
    
                        .theme-light & {
                            color: $color-black;
                        }
                    }
                }
            }
        }
    }

    .caption {
        position:absolute;
        right:auto;
        left:50%;
        bottom:-40px;

        > p {
            @include fluid-type($font-size-body, $font-size-body * 0.75);

            @include screen-tablet-landscape-and-bigger {
                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }

    h1 {
        @include screen-tablet-landscape-and-bigger {
        max-width: 1700px;
        }
    }
}

.mute-button{
	position: absolute;
    right: 10px;
    bottom: 20px;
    mix-blend-mode: difference;
	cursor:pointer;
	opacity:0.5;

	@include screen-tablet-landscape-and-bigger {
		right: 20px;
		bottom: 40px;
	}

	&:hover{
		opacity:1;
		transition: opacity 0.5s linear;
	}
}
