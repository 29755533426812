.people {
    position: relative;
    opacity: 0;
    padding-top: $gutter-vertical-micro*6;
    padding-bottom: $gutter-vertical-micro*6;
    overflow: hidden;
    @include screen-tablet-portrait-and-bigger {
        padding-top: $gutter-vertical-huge;
        padding-bottom: $gutter-vertical-huge;
    }
    .flickity-viewport {
        .flickity-slider {
            position: relative;
            height: 100%;
            width: 100%;
            .person {
                position: absolute;
                left: 0px;
                width: 47.5vw;
                padding: 0 3px;
                @include screen-tablet-portrait-and-bigger {
                    width: 32vw;
                }
                @include screen-laptop-and-bigger {
                    width: 24.5vw;
                }
                .person__image {
                    height: 100%;
                    padding: 0 0 15px 0;
                    display: contents;
                    figure {
                        height: 100%;
                        img {
                            width: 100%;
                        }
                    }
                }
                .person__meta {
                    margin-top: $gutter-vertical-micro;
                    @include screen-tablet-portrait-and-bigger {
                        margin-top: $gutter-vertical-micro*1.5;
                    }
                    h3 {
                        color: $color-white;
                        margin-bottom: 0;
                        @include fluid-type($font-size-body, $font-size-body * 0.6);
                    }
                    p {
                        color: $color-white;
                        margin-bottom: 0;
                        @include fluid-type($font-size-body, $font-size-body * 0.6);
                    }
                }
            }
        }
        &.is-pointer-down {
            cursor: grabbing;
        }
    }
    &.is-ready {
        opacity: 1;
    }
}

// .carousel {
//     display: grid;
//     grid-auto-flow: column;
//     overflow: scroll;
//     overflow-y: auto;
//     padding-top: 60px;
//     padding-bottom: 120px;
    
//     .carousel-wrapper {
//         display: flex;
//         gap: 1px;
//         max-width: fit-content;
//         .carousel-card {
//             position: relative;
//             display: flex;
//             justify-content: center;
//             overflow: hidden;
//             flex-direction: column;
//             scroll-snap-align: center;
//             min-width: 200px;
//             width: 40vw;
//             @include screen-tablet-portrait-and-bigger {
//                 width: 32vw;
//             }
//             @include screen-laptop-and-bigger {
//                 width: 24.5vw;
//             }
            
//             .person__image {
//                 height: 100%;
//                 padding: 0 0 15px 0;
//                 display: contents;
//                 figure {
//                     height: 100%;
//                     img {
//                         width: 100%;
//                     }
//                 }
//             }
//             .person__meta {
//                 margin-top: 20px;
//                 h3 {
//                     @include fluid-type($font-size-body, $font-size-body * 0.73);
//                     color: $color-white;
//                 }
//                 p {
//                     @include fluid-type($font-size-body, $font-size-body * 0.73);
//                     color: $color-grey;
//                     margin-bottom: 0;
//                 }
//             }
//         }
//     }
//     -ms-overflow-style: none;
//     scrollbar-width: none;

//     &.active {
//         cursor: grabbing;
//     }
// }

.carousel::-webkit-scrollbar {
    display: none;
}

.t-about, .t-services, .t-jobs, .t-talks, .t-awards {
    margin-bottom: $gutter-vertical-bigger;
    @include screen-tablet-landscape-and-bigger {
        margin-bottom: $gutter-vertical-insane;
    }

    p {
        // font-size: $font-size-body;
        @include fluid-type($font-size-body, $font-size-body * 0.75);
        line-height: 1.25;

        & + p {
            margin-top: $gutter-vertical-micro*1.5;
            @include screen-tablet-landscape-and-bigger {
                margin-top: $gutter-vertical-micro*3;
            }
        }
    }

    ul {
        margin-top: $gutter-vertical-micro*1.5;
        @include screen-tablet-landscape-and-bigger {
            margin-top: $gutter-vertical-micro*3;
        }
        &.two-column-list {
            li {
                list-style: none;
                margin-bottom: $gutter-vertical-micro*1.5;
                display: flex;
                @include fluid-type($font-size-body, $font-size-body * 0.75);
                flex-direction: column;
                @include screen-tablet-landscape-and-bigger {
                    flex-direction: row;
                }
                span {
                    min-width: $gutter-vertical-bigger;
                    @include screen-tablet-landscape-and-bigger {
                        min-width: $gutter-vertical-huge;
                    }
                }
                a {
                    margin-top: 0;
                }
            }
        }
    }

    a {
        @include fluid-type($font-size-body, $font-size-body * 0.75);
        color: $color-grey;
        margin-top: $gutter-vertical-small;
        @include screen-tablet-landscape-and-bigger {
            margin-top: $gutter-vertical-micro*3;
        }

        &:hover {
            color: $color-white;
        }

        &.about-link {
            display:block;
            @include fluid-type($font-size-body, $font-size-body * 0.75);
        }
        & + p {
            margin-top: 40px;
            @include screen-tablet-landscape-and-bigger {
                margin-top:35px;
            }
        }
    }

    h3 {
        @include fluid-type($font-size-body, $font-size-body * 0.75);
        color: $color-white;
        margin-bottom: $gutter-vertical-micro*1.5;
    }

    .mega-title {
        line-height: .9;
        margin-bottom: $gutter-vertical-micro*8;
        letter-spacing: -.02em;

        @include fluid-type($font-size-huge, $font-size-huge * 0.3);
        @include screen-tablet-landscape-and-bigger {
            letter-spacing: -.04em;
            line-height: .85;
            margin-left: -10px;
            margin-bottom: $gutter-vertical-huge;
        }
        span {
            display: inline-flex;
        }
    }

    section {
        margin-top: $gutter-vertical-huge;
        &:first-of-type {
            margin-top: 0;
        }
        & + section {
            margin-top: $gutter-vertical-micro*6;
            @include screen-tablet-landscape-and-bigger {
                margin-top: $gutter-vertical-bigger;
            }

            &.bigger-margin {
                margin-top: $gutter-vertical-micro*6;
                @include screen-tablet-landscape-and-bigger {
                    margin-top: $gutter-vertical-huge;
                }
                & + section {
                    margin-top: $gutter-vertical-micro*6;
                    @include screen-tablet-landscape-and-bigger {
                        margin-top: $gutter-vertical-huge;
                    }
                }
                .o-col-12 {
                    &+.o-col-12 {
                        margin-top: $gutter-vertical-micro;
                        @include screen-tablet-landscape-and-bigger {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

    .about__large-image {
        
    }

    .gallery-wrapper {
        display: flex;
        overflow: hidden;
        position: relative;
        height: 50vh;
        @include screen-tablet-landscape-and-bigger {
            height: 95vh;
        }
        .gallery {
            position: relative;
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            .gallery-item {
                opacity: 0;
                position: absolute;
                width: 100%; 
                height: 100%;
                figure {
                    width: 100%; 
                    height: 100%;
                    img {
                        object-fit: cover;
                        height: 100% ;
                        width: 100%; 
                    }
                }
            }
            .gallery-item_visible {
                opacity: 1;
            }
        }       
    }

    .galleryTalks{
        margin:50px 0;
        @include screen-tablet-landscape-and-bigger {
            margin:160px 0;
        }
    }
  

    // .people {
    //     opacity: 0;

    //     &.is-ready {
    //         opacity: 1;
    //     }

    //     @include screen-tablet-landscape-and-bigger {
    //         margin-top: 50px;
    //     }
    // }


    // .person {
    //     display: block;
    //     width: 66%;

    //     @include screen-tablet-landscape-and-bigger {
    //         width: 32%;
    //     }

    //     @include screen-laptop-and-bigger {
    //         width: 24.75%;
    //     }
    // }

    // .person__image {
    //     display: block;
    //     width: 100%;
    //     height: auto;
    //     position: relative;

    //     figure {
    //         width: 100%;

    //         img {
    //             width: 100%;
    //         }
    //     }
    // }

    // .person__meta {
    //     display: block;
    //     overflow: hidden;
    //     padding: 15px 20px 0;

    //     h3 {
        // @include fluid-type($font-size-body, $font-size-body * 0.75);
    //         color: $color-white;
    //     }

    //     p {
        // @include fluid-type($font-size-body, $font-size-body * 0.75);
    //         color: $color-grey;
    //         margin-bottom:20px;

    //         @include screen-tablet-landscape-and-bigger {
    //             margin-bottom:80px;
    //         }
    //     }
    // }

    /*! Flickity v2.0.3
        http://flickity.metafizzy.co
        ---------------------------------------------- */

    // .flickity-enabled {
    //     position: relative;
    // }

    // .flickity-enabled:focus {
    //     outline: none;
    // }

    // .flickity-viewport {
    //     overflow: hidden;
    //     position: relative;
    //     height: 100%;
    // }

    // .flickity-slider {
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    // }

    // .flickity-enabled.is-draggable {
    //     -webkit-tap-highlight-color: transparent;
    //     -webkit-user-select: none;
    //     -moz-user-select: none;
    //     -ms-user-select: none;
    //     user-select: none;
    // }

    // .flickity-enabled.is-draggable .flickity-viewport {
    //     cursor: grab;
    // }

    // .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    //     cursor: grabbing;
    // }

    // .flickity-prev-next-button {
    //     display: none;
    //     -webkit-transform: translateY(-50%);
    //     transform: translateY(-50%);
    //     opacity: 1;
    //     @include screen-tablet-landscape-and-bigger {
    //     opacity: 0;
    //     display: block;
    //     position: absolute;
    //     top: 50%;
    //     width: 44px;
    //     height: 44px;
    //     border: 0;
    //     border-radius: 50%;
    //     background: none;
    //     cursor: pointer;
    //     }
    // }

    // .people:hover .flickity-prev-next-button {
    //     opacity: 1;
    // }

    // .flickity-prev-next-button:hover .arrow {
    //     fill: $color-white;
    // }

    // .flickity-prev-next-button:focus {
    //     outline: none;
    //     box-shadow: 0 0 0 5px #09f;
    // }

    // .flickity-prev-next-button:active {
    //     opacity: 0.6;
    // }

    // .flickity-prev-next-button.previous {
    //     left: 10px;
    // }

    // .flickity-prev-next-button.next {
    //     right: 10px;
    // }

    // .flickity-rtl .flickity-prev-next-button.previous {
    //     left: auto;
    //     right: 0;
    // }

    // .flickity-rtl .flickity-prev-next-button.next {
    //     right: auto;
    //     left: 0;
    // }

    // .flickity-prev-next-button:disabled {
    //     opacity: 0.3;
    //     cursor: auto;
    // }

    // .flickity-prev-next-button svg {
    //     position: absolute;
    //     left: 20%;
    //     top: 20%;
    //     width: 60%;
    //     height: 60%;
    // }

    // .flickity-prev-next-button .arrow {
    //     fill: $color-grey;
    // }

    // .flickity-page-dots {
    //     display: none;
    // }

    // .flickity-rtl .flickity-page-dots {
    //     direction: rtl;
    // }

    // .flickity-page-dots .dot {
    //     display: inline-block;
    //     width: 10px;
    //     height: 10px;
    //     margin: 0 8px;
    //     background: #333;
    //     border-radius: 50%;
    //     opacity: 0.25;
    //     cursor: pointer;
    // }

    // .flickity-page-dots .dot.is-selected {
    //     opacity: 1;
    // }

    .jobs-section {
        // margin-bottom:75px;

        // p:last-of-type {
        //     margin-bottom: 0;
        // }

        // @include screen-tablet-landscape-and-bigger {
        //     margin-bottom:120px;
        // }

        h3.white{
            color:$color-white;
        }

        ul.jobs-specs{
            margin-bottom:40px;
            margin-left:20px;
            @include fluid-type($font-size-body, $font-size-body * 0.75);
            line-height: 1.25;
            list-style-type: none;

            @include screen-tablet-landscape-and-bigger {
                margin-bottom:50px;
            }

            & > li {
                text-indent: -20px;
            }

            & > li:before {
                content: "\2014\a0 " ;
                text-indent: -20px;
            }

            @include screen-tablet-portrait-and-bigger {
                margin-left:29px;
                & > li {
                    text-indent: -29px;
                }
                & > li:before {
                    text-indent: -29px;
                }
            }

            @include screen-tablet-landscape-and-bigger {
                margin-left:34px;
                & > li {
                    text-indent: -34px;
                }
                & > li:before {
                    text-indent: -34px;
                }
            }

        }
    }
}
