/*----------------------------------*\
  #GRID
\*----------------------------------*/

$grid-gutter-small-breakpoint         : $breakpoint-tablet-landscape;
$grid-breakpoints                     : ( 'sm': $breakpoint-mobile-plus ,
                                          'md': $breakpoint-tablet-portrait,
                                          'lg': $breakpoint-tablet-landscape,
                                          'xlg': $breakpoint-laptop );

$grid-config                          : ( max-width: $breakpoint-desktop,
                                          columns: 12,
                                          // grid-gutter: 20px,
                                          // grid-gutter-small: 15px,
                                          // container-gutter: 20px,
                                          grid-gutter: 5px,
                                          grid-gutter-small: 5px,
                                          container-gutter: 10px,
                                          container-gutter-small: 5px,
                                          add-push-pull: true,
                                          add-pre-post: true  );
