$transition-speed: 1000ms;

.theme-dark {
  background-color: $color-dark;
  color: $color-white;
  transition: background-color $transition-speed;
}

.theme-darkgrey{
    background-color: $color-off-black;
  color: $color-white;
  transition: background-color $transition-speed;
}

.theme-light {
  background-color: $color-white;
  color: $color-black;
  transition: background-color $transition-speed;
}
