.t-work {
  &__padding {
    padding-top: $gutter-vertical-bigger;
    margin-bottom: $gutter-vertical-insane;
    @include screen-tablet-landscape-and-bigger {
      padding-top: $gutter-vertical-bigger;
    }
  }
  
  .projects-archive {
    @include fluid-type($font-size-body, $font-size-body * 0.75);
    margin: $gutter-vertical-micro*16 0 0 0;
    color: $color-grey;
    line-height: 1.375;
    
    span {
      color: $color-white;
      margin: 0 0 $gutter-vertical-micro*1.5 0;
      line-height: 1;
      display: block;
      @include screen-tablet-portrait-and-bigger {
        display: contents;
      }
      &.divider {
        color: $color-grey;
        display: none;
        @include screen-tablet-portrait-and-bigger {
          display: contents;
        }
      }
    }

    a {
      display: block;
      line-height: 1.375;
      color: $color-grey;
      white-space: break-spaces;
      @include screen-tablet-portrait-and-bigger {
        display: contents;
        white-space: nowrap;
      }
      &:hover {
        color: $color-white;
      }
    }
    transform: scale(1);
    transition: transform .3s $ease-out-circ-special-two;
    transform-origin: left top;
    .PT-work_out & {
      transform: scale(.8);
    }
  }

  figure {
    margin-bottom: 15px;

    @include screen-tablet-landscape-and-bigger {
        margin-bottom:20px;
    }
  }

  h3 {
    @include fluid-type($font-size-large, $font-size-large * 0.625);
    color: $color-grey;

    & .related__title {
        color: inherit;
    }
  }

  .client {
    color: $color-grey;

    &:hover {
      color: $color-grey;
    }
  }

  .project-link {
    margin-bottom: $gutter-vertical-regular;
    color: $color-grey;
    @include screen-tablet-landscape-and-bigger {
        &:nth-last-child(2) {
          margin-bottom: 0;
        }
    }

    .figure-video, .figure-image {
      transition: transform .3s $ease-out-circ-special-two;
      // background-color: red;
      // video {
      //   opacity: 0;
      // }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    figcaption,
    .figcaption-name {
      transition: transform .3s $ease-out-circ-special-two;
      transform-origin: top left;
    }

    figure {
        position:relative;
        margin:0;
        video,picture {
          aspect-ratio: 4/5;
          background-size: cover;
        }
        picture {
          width: 100%;
          display: inline-block;
        }
    }

    figcaption {
        @include screen-tablet-landscape-and-bigger {
            position:absolute;
        }
    }

    &:hover {
      figcaption,
      .figcaption-name {
        color: $color-white;
      }
    }

    @include screen-tablet-landscape-and-bigger {
      margin-bottom: $gutter-vertical-bigger;
    }
  }
}
