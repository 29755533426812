figure {
    width: 100%;
}

figcaption {
    width: 100%;
    padding-top: $gutter-vertical-micro;

    @include screen-tablet-landscape-and-bigger {
        padding-top: $gutter-vertical-micro*1.5;
    }
}

.figcaption-name {
    color: $color-grey;
    // position: absolute;
}

.figcaption-caption {
    // left: 50%;
    // position: relative;
}

.figure-image {
    margin-bottom:$gutter-vertical-micro*1.5;
    @include screen-tablet-landscape-and-bigger {
        margin-bottom:$gutter-vertical-small;
    }
}

.figure-video {
    margin-bottom:$gutter-vertical-micro*1.5;
    @include screen-tablet-landscape-and-bigger {
        margin-bottom:$gutter-vertical-small;
    }

    video {
        width:100%;
        max-width:100%;
        vertical-align: top;
    }
}

.figure--extra-margin {
    margin-bottom: 0;

    @include screen-tablet-landscape-and-bigger {
        margin-bottom: $gutter-vertical-big;
    }
}

.figure--full-width {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin: 0 -10px 0 -10px;

    figure {
        width: calc(100vw);
        padding: 0;
    }

    @include screen-tablet-landscape-and-bigger {
        // margin-bottom: $gutter-vertical-big;
    }
}