/*----------------------------------*\
  #COLORS
\*----------------------------------*/

// Color palette

$color-primary      : #ff3000;
$color-secondary    : #333333;
$color-tertiary     : #fafafa;

$color-black        : #000000;
$color-grey-dark    : #767676;
$color-grey         : rgb(134, 134, 134);
$color-grey-light   : #808080;
$color-highlight-dark: rgb(32, 32, 32);

$color-dark         : #000000;
$color-yellow       : #F4E45E;

$color-white        : #ffffff;
$color-off-white    : #E0E0E0;
$color-off-black    : #151515;



// Core color settings

$color-background-html      : $color-white;
$color-background-primary   : $color-white;

$color-text-header          : $color-grey-dark;
$color-text                 : $color-grey-dark;

$color-divider-line         : $color-grey-light;

$color-form-placeholder     : $color-grey;
$color-form-focus           : $color-primary;



// Social color palette

$color-social-twitter     : #00aced;
$color-social-facebook    : #3b5998;
$color-social-googleplus  : #dd4b39;
$color-social-pinterest   : #cb2027;
$color-social-linkedin    : #007bb6;
$color-social-youtube     : #bb0000;
$color-social-vimeo       : #aad450;
$color-social-instagram   : #517fa4;
$color-social-flickr      : #ff0084;
$color-social-dribbble    : #ea4c89;
$color-social-whatsapp    : #4dc247;

// List of social channels with their colors you can use to loop through
$color-socials-map        : ( 'twitter'   : $color-social-twitter,
                              'facebook'  : $color-social-facebook,
                              'linkedin'  : $color-social-linkedin,
                              'youtube'   : $color-social-youtube );
