blockquote {
    p {
        font-size: $font-size-large;
        line-height: 1.15;
        margin-bottom:40px;

        @include screen-tablet-landscape-and-bigger {
            font-size: $font-size-h1;
            line-height: $font-line-height-h1;
            letter-spacing:-2px;
        }

        cite {
            @include fluid-type($font-size-body, $font-size-body * 0.75);
            color: $color-grey;
            font-style: normal;
            letter-spacing:0;
        }
    }
}
