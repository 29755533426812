// sass-lint:disable no-transition-all

$transition-speed: 1000ms;

.t-default {
    padding-top:$gutter-vertical-bigger;
    @include screen-tablet-landscape-and-bigger {
        padding-top:$gutter-vertical-huge;
    }
    strong {
        margin-bottom: 0;
        display: inline-block;
        @include screen-tablet-landscape-and-bigger {
            margin-bottom: $gutter-vertical-micro;
        }
    }
}

.t-work {
  opacity: 1;
  transition: opacity $transition-speed;
    &.hide {
        opacity: 0;
    }
}

.content {
  a {
    color:$color-grey;
        &:hover {
            color:$color-white;
        }
    }
}


.content-cookie{
    ul{
        @include fluid-type($font-size-body, $font-size-body * 0.6);
        margin-bottom: $gutter-vertical-micro*3;
        margin-left: $gutter-vertical-micro*3;
        line-height: 1.25;
    }
    p.no-margin{
        margin-bottom: $gutter-vertical-micro*3;
    }
}

#cc_div{
    font-family: $font-stack-primary !important;
    color: #fff !important;
    #cm {
        width: 100%;
        max-width:none !important;
        background:#000 !important;
        box-shadow: none !important;
        bottom:0 !important;
        right:0 !important;
        left:0% !important;
        padding: $gutter-vertical-micro*1.5 $gutter-vertical-small;
        border-radius: 0;
        border-top: 0px rgb(56, 56, 56) solid;
        #c-inr {
            display: flex;
            justify-content: space-between;
            // align-items: center;
            flex-direction: row;
            row-gap: $gutter-vertical-micro*3;
            #c-txt {
                font-size: $font-size-body !important;
                line-height: 1.25;
                a {
                    border-bottom: 0 !important;
                    &:hover {
                        color: $color-white !important;
                    }
                }
            }
            #c-inr-i {
                width: 40%;
                align-self: center;
            }
            #c-bns {
                margin-top: 0;
                align-self: center;
                button {
                    background-color: transparent;
                    font-size: $font-size-body*0.75 !important;
                    white-space: nowrap;
                    padding: $gutter-vertical-micro $gutter-vertical-regular;
                    color: $color-grey !important;
                    border: 1px $color-grey solid !important;
                    font-weight: 400;
                    height: fit-content;
                    &+button {
                        margin-left: $gutter-vertical-micro*1.5;
                    }
                    transition-duration: .0s !important;
                    transition-property: border-color, color, background-color !important;
                    &:hover {
                        color: $color-white !important;
                        border-color: $color-white !important;
                        background-color: $color-black !important;
                    }
                    
                }
                #c-p-bn {
                    background-color: $color-black !important;
                    color: $color-grey !important;
                    border-color: $color-grey !important;
                    transition-duration: 0s !important;
                    transition-property: border-color, color, background-color !important;
                    &:hover {
                        border-color: $color-white !important;
                        color: $color-white !important;
                    }
                }
            }
        }
    }
    #c-txt a{
        color: $color-grey !important;
        text-decoration: none !important;
        transition: 0;
        transition-property: color !important;
        a {
            transition: 0;

        }
    }
    #c-txt{
        font-size: $font-size-body*0.75 !important;
    }
    #c-ttl{
        font-size: calc(16.06px + 5.94 * (100vw - 480px) / 760) !important;
        @include screen-tablet-landscape-and-bigger {
            font-size: 22px !important;
        }

        margin-bottom:0 !important;
        color: $color-grey !important;
    }
        #c-p-bn{
            border-radius: 0 !important;
            background: #fff !important;
            color: #000 !important;
            font-size: 16px !important;
            &:hover{
                background: $color-grey !important;
            }
        }

        #c-s-bn{
            border-radius: 0 !important;
            border:2px solid #FFF !important;
            color: #fff !important;
            font-size: 16px !important;
            &:hover{
                border:2px solid $color-grey !important;
                background: $color-grey !important;
                color: #000 !important;
            }
        }

        
}

// #cc_div{
//     font-family: $font-stack-primary !important;
//     color: #fff !important;
//     #cm {
//         width: 50%;
//         max-width:none !important;
//         background:#000 !important;
//         box-shadow: none !important;
//         bottom:0 !important;
//         right:0 !important;
//         left:50% !important;
//         padding: $gutter-vertical-micro*1.5 $gutter-vertical-small;
//         border-radius: 0;
//         // border-top: 1px $color-white solid;
//         #c-inr {
//             display: flex;
//             // justify-content: space-between;
//             // align-items: center;
//             flex-direction: column;
//             row-gap: $gutter-vertical-micro*3;
//             #c-txt {
//                 font-size: $font-size-body !important;
//                 line-height: 1.25;
//                 a {
//                     border-bottom: 0 !important;
//                     &:hover {
//                         color: $color-white !important;
//                     }
//                 }
//             }
//             #c-inr-i {
//                 width: 100%; 
//             }
//             #c-bns {
//                 margin-top: 0;
//                 button {
//                     font-size: $font-size-body*0.75 !important;
//                     white-space: nowrap;
//                     padding: $gutter-vertical-micro $gutter-vertical-regular;
//                     color: $color-white !important;
//                     border: 1px $color-white solid !important;
//                     font-weight: 400;
//                     height: fit-content;
//                     &+button {
//                         margin-left: $gutter-vertical-micro;
//                     }
//                     transition: $link-transition-time $link-transition-ease;
//                     transition-property: border-color, color, background-color !important;
//                     &:hover {
//                         color: $color-grey !important;
//                         border-color: $color-grey !important;
//                         background-color: $color-black !important;
//                     }
//                 }
//                 #c-p-bn {
//                     background-color: $color-black !important;
//                     color: $color-white !important;
//                     transition: $link-transition-time $link-transition-ease;
//                     transition-property: border-color, color, background-color !important;
//                     &:hover {
//                         color: $color-grey !important;
//                         border-color: $color-grey !important;
//                     }
//                 }
//             }
//         }
//     }
//     #c-txt a{
//         color: $color-grey !important;
//         text-decoration: none !important;
//         transition: $link-transition-time $link-transition-ease;
//         transition-property: color !important;
//     }
//     #c-txt{
//         font-size: $font-size-body*0.75 !important;
//     }
//     #c-ttl{
//         font-size: calc(16.06px + 5.94 * (100vw - 480px) / 760) !important;
//         @include screen-tablet-landscape-and-bigger {
//             font-size: 22px !important;
//         }

//         margin-bottom:0 !important;
//         color: $color-grey !important;
//     }
//         #c-p-bn{
//             border-radius: 0 !important;
//             background: #fff !important;
//             color: #000 !important;
//             font-size: 16px !important;
//             &:hover{
//                 background: $color-grey !important;
//             }
//         }

//         #c-s-bn{
//             border-radius: 0 !important;
//             background: #000 !important;
//             border:2px solid #FFF !important;
//             color: #fff !important;
//             font-size: 16px !important;
//             &:hover{
//                 border:2px solid $color-grey !important;
//                 background: $color-grey !important;
//                 color: #000 !important;
//             }
//         }

// }
