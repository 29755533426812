.t-awards {
    .table-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: $gutter-vertical-micro*14;
        &:last-of-type {
            margin-bottom: 0;
        }
        @include screen-tablet-landscape-and-bigger {
            flex-direction: row;
        }
        h3 {
            color: $color-white;
            @include fluid-type($font-size-h2, 4);
            line-height: .9;
            font-weight: $font-weight-regular;
            letter-spacing: -0.025em;
            margin-bottom: $gutter-vertical-small;
            margin-left: -3px;
            margin-right: $gutter-vertical-small;
            width: 25%;
        }
    
        table {
            border-collapse: separate;
            border-spacing: 0 10px;
            width: 75%;
            display: flex;
            @include screen-tablet-portrait-and-smaller {
                border-spacing: 0 30px;
            }
    
            tbody {
                width: 100%;
                display: flex;
                flex-direction: column;
                row-gap: $gutter-vertical-micro*1.5;
                @include screen-tablet-landscape-and-bigger {
                    row-gap: $gutter-vertical-micro*.5;
                }
                tr {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    @include screen-tablet-landscape-and-bigger {
                        flex-direction: row;
                    }
                    td {
                        padding-right: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 100%;
                        margin: 0;
                        @include fluid-type(1.5, 1.2);
                        line-height: 1.25;
                
                        &:last-of-type {
                            padding-right: 0;
                        }
                
                        @include screen-tablet-portrait-and-smaller {
                            display: block;
                            padding-right: 0;
                            width: 100%;
                
                            &::before {
                                display: block;
                                float: left;
                                width: 30%;
                                padding-right: 20px;
                                content: attr(data-label);
                            }
                        }
                    }
                }
            }
        }
    }
}
