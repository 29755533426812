$hamburger-layer-width         : 35px !default;
$hamburger-layer-height        : 1px !default;
$hamburger-layer-spacing       : 6px !default;
$hamburger-layer-color         : #868686 !default;
$hamburger-layer-border-radius : none !default;
$hamburger-hover-opacity       : 0.7 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

header,
.home-header {
    padding: $gutter-vertical-small 0;
    top: 0;
    z-index: 3;
    width: 100%;
    @include fluid-type($font-size-body, $font-size-body * 0.75);
    color: $color-white;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: difference;

    @include screen-tablet-landscape-and-bigger {
        position: fixed;
        padding: $gutter-vertical-micro*1.5 0;

    }

    .theme-light & {
        color: $color-black;
    }

    nav {
        @include screen-tablet-landscape-and-bigger {
            justify-content: space-between;
            display: flex;
        }

        .header__logo {
            position: fixed;
            padding: 1px 3px;
            color: $color-grey;
            height:auto;
            display:inline-block;
            pointer-events: all;
            cursor: pointer;
            z-index: 10;
            @include screen-tablet-landscape-and-bigger {
                position: relative;
            }

            svg {
                height: $gutter-vertical-micro*1.3;
                width: initial;
                @include screen-mobile-plus-and-bigger {
                    height: $gutter-vertical-micro*1.35;
                }
                @include screen-tablet-landscape-and-bigger {
                    height: $gutter-vertical-micro*1.5;
                }
            }

            &:hover {
                color: $color-white;

                .theme-light & {
                    color: $color-white;
                }
            }
        }

        .menu-toggle {
            position: fixed;
            z-index: 2;
            top: 0;
            right: 0;
            padding: $gutter-vertical-small + 3 $gutter-vertical-small - 2 $gutter-vertical-small + 3 $gutter-vertical-small + 3;
            pointer-events: all;
            @include screen-tablet-landscape-and-bigger {
                display: none;
            }

            .hamburger {
                display: flex;
                cursor: pointer;
                color: inherit;
                text-transform: none;
                background-color: transparent;
                border: 0;
                margin: 0;
                overflow: visible;

                .hamburger-box {
                    width: $hamburger-layer-width;
                    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
                    display: inline-block;
                    position: relative;

                    .hamburger-inner {
                        display: block;
                        top: 50%;
                        margin-top: $hamburger-layer-height / -2;
                        transition: transform .4s $ease-out-circ-special-two, opacity .0s linear .4s;
        
                        &,
                        &::before,
                        &::after {
                            width: $hamburger-layer-width;
                            height: $hamburger-layer-height;
                            background-color: $hamburger-layer-color;
                            position: absolute;
                        }
        
                        &::before,
                        &::after {
                            content: '';
                            display: block;
                        }
        
                        &::before {
                            top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
                        }
        
                        &::after {
                            bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
                        }
                    }

                    .close-inner {
                        display: block;
                        position: absolute;
                        top: 50%;
                        margin-top: $hamburger-layer-height / -2;
                        margin-left: $hamburger-layer-width * -.125;
                        width: $hamburger-layer-width*1.25;
                        height: $hamburger-layer-height;
                        opacity: 0;
                        transform: scale(.5);
                        transition: transform .4s $ease-out-circ-special-two, opacity .0s linear .3s;

                        &::before,
                        &::after {
                            width: $hamburger-layer-width*1.25;
                            height: $hamburger-layer-height;
                            background-color: $hamburger-layer-color;
                            position: absolute;
                        }
        
                        &::before,
                        &::after {
                            content: '';
                            display: block;
                        }
        
                        &::before {
                            transform: rotate(23deg);
                        }
        
                        &::after {
                            transform: rotate(-23deg);
                        }

                    }
                }

                &.active {
                    .hamburger-inner {
                        transition: transform .4s $ease-out-circ-special-two, opacity .0s linear .3s;
                        transform: scale(1.5);
                        opacity: 0;
                    }
                    .close-inner {
                        transition: transform .4s $ease-out-circ-special-two, opacity .0s linear .4s;
                        transform: scale(1);
                        opacity: 1;
                    }
                }
            }

            
        }

        &:not(.mobile-menu) {
            ul {
                display: none;

                @include screen-tablet-landscape-and-bigger {
                    list-style: none;
                    display: flex;
                    width: 50%;
                    justify-content: space-between;
                    padding-left: $gutter-vertical-micro;
                }

                a {
                    color: $color-grey;
                    cursor: pointer;
                    line-height: 1;
                    pointer-events: all;

                    &.active,
                    &:hover {
                        color: $color-white;

                        .theme-light & {
                            color: $color-white;
                        }
                    }
                    .homepage & {
                        color: $color-white;
                        &:hover {
                            color: $color-grey;
                        }
                    }
                }
            }
        }
    }

    .white-nav{
        nav {
            ul a,
            a {
                color: $color-white;
            }
        }
    }

    .mobile-menu {
        position: fixed;
        display: flex;
        align-items: flex-end;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100dvh;
        padding: $gutter-vertical-small - 2px;
        transition: opacity .0s linear .3s;
        pointer-events: none;

        @include screen-tablet-landscape-and-bigger {
            display: none;
        }

        &.active {
            pointer-events: all;
            li {
                @mixin transition {
                    @for $i from 1 through 5 {
                        &:nth-child(#{$i}) {
                            a {
                                transition: transform .2s $ease-out-circ-special #{$i * .05}s, opacity .0s linear #{$i * .05}s;
                            }
                        }
                    }
                }
                @include transition;
                a {
                    opacity: 1;
                    transform: scale(1);
                    .PT-general_out & {
                        transform: scale(.9);
                        opacity: 0;
                        transition: transform .2s $ease-out-circ-special-two .2s, opacity .0s linear .4s;
                    }
                    .PT-work_out & {
                        transform: scale(.9);
                        opacity: 0;
                        transition: transform .2s $ease-out-circ-special-two .2s, opacity .0s linear .4s;
                    }
                    &.chosen-page {
                        .PT-work_out &, .PT-general_out & {
                            transform: scale(1.125);
                            opacity: 0;
                            transition: transform .2s $ease-out-circ-special-two .2s, opacity .0s linear .4s;
                        }
                    }
                }
            }
        }

        ul {
            @include fluid-type($font-size-huge * 0.5, $font-size-huge * 0.3);
            letter-spacing: -.02em;
            list-style:none;
            line-height: .8;
            display: flex;
            flex-direction: column;
            row-gap: $gutter-vertical-micro*1.5;
            width: 100%;
            @include screen-tablet-landscape-and-bigger {
                letter-spacing: -.04em;
            }
            li {
                a {
                    display: block;
                    color: $color-grey;
                    opacity: 0;
                    transform: scale(.9);
                    transform-origin: top left;
                    transition: transform .2s $ease-out-circ-special-two .2s, opacity .0s linear .4s;
                    &:hover {
                        color: $color-white;
                    }
                    &.active {
                        color: $color-white;
                    }
                }
                .home &, .temp-project & {
                    a {
                        color: $color-white;
                    }
                }
                // .temp-project & {
                //     a {
                //         color: $color-white;
                //     }
                // }
            }
            &:hover {
                li {
                    a {
                        color: $color-grey;
                        &.active {
                            color: $color-grey;
                        }
                        &:hover {
                            color: $color-white;
                        }
                    }
                }
            }
        }
    }
}

.home-header {
    color: $color-white;

    nav {
        a {
            color: $color-white;

            @include screen-tablet-landscape-and-bigger {
                &:hover {
                    color: $color-white;
                }
            }
        }
    }
}
