.t-contact {

    padding-bottom:40px;

    h3 {
        @include fluid-type($font-size-body, $font-size-body * 0.75);
        color: $color-grey;
    }

    p {
        @include fluid-type($font-size-body, $font-size-body * 0.75);
        line-height: 1.25;
        margin-bottom:$gutter-vertical-micro*3;

        @include screen-tablet-landscape-and-bigger {
            margin-bottom:$gutter-vertical-regular;
        } 
        &.small {
            @include fluid-type($font-size-body, $font-size-body * 0.75);
        }
    }

    .contact-section {
    }

    body & {
        height: 100%;
    }

    + .c-footer {

        margin-top:80px;

        @include screen-tablet-landscape-and-bigger {
            margin-top:0;
            position: fixed;
            bottom: 0;
            width: 100%;
        }
    }
}
