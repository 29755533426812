$link-transition-time: 200ms;
$link-transition-ease: linear;

// most basic link style
@mixin link--base {
  color: inherit;
  cursor: pointer;
  text-overflow: ellipsis;
  transition: $link-transition-time $link-transition-ease;
  transition-property: border, background-color;
  text-decoration:none;
}

a {
  @include link--base;
}
