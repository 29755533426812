// sass-lint:disable-all

[data-aos="topleft-hardscale"] {
  opacity: 0;
  transform: scale(.8);
  transform-origin: top left;
  transition: transform .3s $ease-out-circ-special .2s, opacity .0s linear .3s;
  @include screen-mobile-plus-and-bigger {
    transform: scale(.8);
  }
  &.aos-animate {
    opacity: 1;
    transform: scale(1);
  }
  .t-contact & {
    @include screen-mobile-plus-and-smaller {
      transition: transform .0s, opacity .0s;
    }
  }
}

// ————————————————————————————————————————————————
// Contact

[data-aos="topleft-hardscale-contact"] {
  @include screen-mobile-plus-and-smaller {
    opacity: 0;
    transform: scale(.9);
    transform-origin: top left;
    transition: transform .3s $ease-out-circ-special .2s, opacity .0s linear .3s;
    &.aos-animate {
      opacity: 1;
      transform: scale(1);
    }
  }
}

// ————————————————————————————————————————————————
// Awards

[data-aos="topleft-hardscale-table"] {
    opacity: 0;
    transform: scale(.8);
    transform-origin: top left;
    transition: transform .3s $ease-out-circ-special .2s, opacity .0s linear .3s;
    &.aos-animate {
      opacity: 1;
      transform: scale(1);
    }
}

// ————————————————————————————————————————————————

[data-aos="topleft-hardscale-small"] {
  opacity: 0;
  transform: scale(.85);
  transform-origin: top left;
  transition: transform .3s $ease-out-circ-special, opacity .0s linear .05s;
  &.aos-animate {
    opacity: 1;
    transform: scale(1);
  }
}

[data-aos="topleft-hardscale-bigger_delay"] {
  opacity: 0;
  transform: scale(.9);
  transform-origin: top left;
  transition: transform .3s $ease-out-circ-special .5s, opacity .0s linear .55s;
  &.aos-animate {
    opacity: 1;
    transform: scale(1);
  }
  .t-services & {
    transition: transform .3s $ease-out-circ-special .9s, opacity .0s linear 1s;
  }
  .project__specs & {
    transition: transform .3s $ease-out-circ-special .2s, opacity .0s linear .2s;
  }
  .spec & {
    @for $i from 1 through 10 {
      &:nth-of-type(#{$i}) {
        transition: transform .3s $ease-out-circ-special #{0.25+$i*0.05s}, opacity .0s linear #{0.25+$i*0.05s};
      }
    }
  }
}

[data-aos="topleft-hardscale-bigger_delay-mobile"] {
  opacity: 0;
  transform: scale(.9);
  transform-origin: top left;
  &.aos-animate {
    opacity: 1;
    transform: scale(1);
  }
  
  transition: transform .3s $ease-out-circ-special .9s, opacity .0s linear 1s;

  @include screen-tablet-landscape-and-bigger {
    transition: transform .3s $ease-out-circ-special .2s, opacity .0s linear .3s;
  }

  // @include screen-desktop-and-bigger {
    @media only screen and (min-width: 130em) {
    transition: transform .3s $ease-out-circ-special 1.1s, opacity .0s linear 1.2s;
    .t-about & {
      transition: transform .3s $ease-out-circ-special .7s, opacity .0s linear .8s;
    }
  }
}

[data-aos="topleft-hardscale-huge_delay"] {
  opacity: 0;
  transform-origin: top left;

  transform: scale(.85);
  transition: transform .3s $ease-out-circ-special .4s, opacity .0s linear .4s;
  @include screen-tablet-landscape-and-bigger {
    transform: scale(.8);
    transition: transform .3s $ease-out-circ-special .6s, opacity .0s linear .7s;
  }
  &.aos-animate {
    opacity: 1;
    transform: scale(1);
  }
}

[data-aos="topleft-slowscale"] {
  opacity: 0;
  transform-origin: top left;
  transform: scale(.85);
  transition: transform .3s $ease-out-circ-special, opacity .0s linear;
  @include screen-tablet-landscape-and-bigger {
    transition: transform .3s $ease-out-circ-special .2s, opacity .0s linear .2s;
  }
  &.aos-animate {
    opacity: 1;
    transform: scale(1);
  }
}

[data-aos="topleft-slowscale-delay"] {
  opacity: 0;
  transform-origin: top left;
  transform: scale(.9);
  transition: transform .3s $ease-out-circ-special, opacity .0s linear ;
  @include screen-tablet-landscape-and-bigger {
    transform: scale(.8);
    transition: transform .3s $ease-out-circ-special .4s, opacity .0s linear .4s;
  }
  &.aos-animate {
    opacity: 1;
    transform: scale(1);
  }
}

[data-aos="topleft-hardscale-steps"] {
  opacity: 0;
  transform-origin: center left;
  transition: transform .3s $ease-out-circ-special .2s, opacity .0s linear .3s;

  transform: scale(.8);
  &.aos-animate {
    opacity: 1;
    transform: scale(1);
  }
  &:nth-child(2) {
    transition: transform .3s $ease-out-circ-special .4s, opacity .0s linear .5s;
  }
  &:nth-child(3) {
    transition: transform .3s $ease-out-circ-special .6s, opacity .0s linear .7s;
  }
}

[data-aos="center-hardscale"] {
  opacity: 0;
  transform: scale(.9);
  transform-origin: center;
  transition: transform .2s $ease-out-circ-special .2s, opacity .0s linear .3s;
  &.aos-animate {
    opacity: 1;
    transform: scale(1);
  }
}

[data-aos="center-hardscale-header"] {
  opacity: 0;
  transform: scale(.75);
  transform-origin: center;
  transition-timing-function: $ease-out-circ-special ;
  transition: transform .3s $ease-out-circ-special .2s, opacity .0s linear .3s;
  &.aos-animate {
    opacity: 1;
    transform: scale(1);
  }
}

[data-aos="center-hardscale-work_page"] {
  opacity: 0;
  transform: scale(.8);
  transform-origin: center;
  transition-timing-function: $ease-out-circ-special;
  &.aos-animate {
    opacity: 1;
    transform: scale(1);
  }

  transition: transform .2s $ease-out-circ-special .2s, opacity .0s linear .25s;

  @include screen-tablet-portrait-and-bigger {
    &:nth-child(even) {
      transition: transform .2s $ease-out-circ-special .4s, opacity .0s linear .45s;
    }
  }

  @include screen-laptop-and-bigger {
    &:nth-child(3n-2) {
      transition: transform .2s $ease-out-circ-special .2s, opacity .0s linear .25s;
    }
    &:nth-child(3n-1) {
      transition: transform .2s $ease-out-circ-special .4s, opacity .0s linear .45s;
    }
    &:nth-child(3n) {
      transition: transform .2s $ease-out-circ-special .6s, opacity .0s linear .65s;
    }
  } 
}

[data-aos="center-hardscale-project_page"] {
  opacity: 0;
  transform: scale(.9);
  transform-origin: center;
  transition-timing-function: $ease-out-circ-special;
  &.aos-animate {
    opacity: 1;
    transform: scale(1);
  }
    &:nth-child(3n-2) {
      transition: transform .2s $ease-out-circ-special .2s, opacity .0s linear .25s;
    }
    &:nth-child(3n-1) {
      transition: transform .2s $ease-out-circ-special .5s, opacity .0s linear .55s;
    }
    &:nth-child(3n) {
      transition: transform .2s $ease-out-circ-special .8s, opacity .0s linear .85s;
    }
}


// Animations variables
$aos-distance: 40px !default;
// Generate Duration && Delay
[data-aos] {
    @for $i from 1 through 60 {
      body[data-aos-duration='#{$i * 50}'] &,
      &[data-aos][data-aos-duration='#{$i * 50}'] {
        // transition-duration: #{$i * 50}ms;
      }
  
      body[data-aos-delay='#{$i * 50}'] &,
      &[data-aos][data-aos-delay='#{$i * 50}'] {
        transition-delay: 0s;
  
        &.aos-animate {
          transition-delay: #{$i * 50}ms;
        }
      }
    }
  }
  
  [data-aos] {
    pointer-events: none;
    &.aos-animate {
      pointer-events: auto;
    }
  }



@media screen {
  html:not(.no-js) {
    /**
    * Fade animations:
    * fade
    * fade-up, fade-down, fade-left, fade-right
    * fade-up-right, fade-up-left, fade-down-right, fade-down-left
    */

    [data-aos^='fade'][data-aos^='fade'] {
      opacity: 0;
      transition-property: opacity, transform;

      &.aos-animate {
        opacity: 1;
        transform: none;
      }
    }

    [data-aos='fade-up'] {
      transform: translate3d(0, $aos-distance, 0);
    }

    [data-aos='fade-down'] {
      transform: translate3d(0, -$aos-distance, 0);
    }

    [data-aos='fade-right'] {
      transform: translate3d(-$aos-distance, 0, 0);
    }

    [data-aos='fade-left'] {
      transform: translate3d($aos-distance, 0, 0);
    }

    [data-aos='fade-up-right'] {
      transform: translate3d(-$aos-distance, $aos-distance, 0);
    }

    [data-aos='fade-up-left'] {
      transform: translate3d($aos-distance, $aos-distance, 0);
    }

    [data-aos='fade-down-right'] {
      transform: translate3d(-$aos-distance, -$aos-distance, 0);
    }

    [data-aos='fade-down-left'] {
      transform: translate3d($aos-distance, -$aos-distance, 0);
    }




    /**
    * Zoom animations:
    * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
    * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
    */

    [data-aos^='zoom'][data-aos^='zoom'] {
      opacity: 0;
      transition-property: opacity, transform;

      &.aos-animate {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
      }
    }

    [data-aos='zoom-in'] {
      transform: scale(.6);
    }

    [data-aos='zoom-in-up'] {
      transform: translate3d(0, $aos-distance, 0) scale(.6);
    }

    [data-aos='zoom-in-down'] {
      transform: translate3d(0, -$aos-distance, 0) scale(.6);
    }

    [data-aos='zoom-in-right'] {
      transform: translate3d(-$aos-distance, 0, 0) scale(.6);
    }

    [data-aos='zoom-in-left'] {
      transform: translate3d($aos-distance, 0, 0) scale(.6);
    }

    [data-aos='zoom-out'] {
      transform: scale(1.2);
    }

    [data-aos='zoom-out-up'] {
      transform: translate3d(0, $aos-distance, 0) scale(1.2);
    }

    [data-aos='zoom-out-down'] {
      transform: translate3d(0, -$aos-distance, 0) scale(1.2);
    }

    [data-aos='zoom-out-right'] {
      transform: translate3d(-$aos-distance, 0, 0) scale(1.2);
    }

    [data-aos='zoom-out-left'] {
      transform: translate3d($aos-distance, 0, 0) scale(1.2);
    }




    /**
    * Slide animations
    */

    [data-aos^='slide'][data-aos^='slide'] {
      transition-property: transform;
      visibility: hidden;

      &.aos-animate {
        visibility: visible;
        transform: translate3d(0, 0, 0);
      }
    }

    [data-aos='slide-up'] {
      transform: translate3d(0, 100%, 0);
    }

    [data-aos='slide-down'] {
      transform: translate3d(0, -100%, 0);
    }

    [data-aos='slide-right'] {
      transform: translate3d(-100%, 0, 0);
    }

    [data-aos='slide-left'] {
      transform: translate3d(100%, 0, 0);
    }




    /**
    * Flip animations:
    * flip-left, flip-right, flip-up, flip-down
    */

    [data-aos^='flip'][data-aos^='flip'] {
      backface-visibility: hidden;
      transition-property: transform;
    }

    [data-aos='flip-left'] {
      transform: perspective(2500px) rotateY(-100deg);
      &.aos-animate {transform: perspective(2500px) rotateY(0);}
    }

    [data-aos='flip-right'] {
      transform: perspective(2500px) rotateY(100deg);
      &.aos-animate {transform: perspective(2500px) rotateY(0);}
    }

    [data-aos='flip-up'] {
      transform: perspective(2500px) rotateX(-100deg);
      &.aos-animate {transform: perspective(2500px) rotateX(0);}
    }

    [data-aos='flip-down'] {
      transform: perspective(2500px) rotateX(100deg);
      &.aos-animate {transform: perspective(2500px) rotateX(0);}
    }
  }
}

$aos-easing: (
  linear: cubic-bezier(.250, .250, .750, .750),

  ease: cubic-bezier(.250, .100, .250, 1),
  ease-in: cubic-bezier(.420, 0, 1, 1),
  ease-out: cubic-bezier(.000, 0, .580, 1),
  ease-in-out: cubic-bezier(.420, 0, .580, 1),

  ease-in-back: cubic-bezier(.6, -.28, .735, .045),
  ease-out-back: cubic-bezier(.175, .885, .32, 1.275),
  ease-in-out-back: cubic-bezier(.68, -.55, .265, 1.55),

  ease-in-sine: cubic-bezier(.47, 0, .745, .715),
  ease-out-sine: cubic-bezier(.39, .575, .565, 1),
  ease-in-out-sine: cubic-bezier(.445, .05, .55, .95),

  ease-in-quad: cubic-bezier(.55, .085, .68, .53),
  ease-out-quad: cubic-bezier(.25, .46, .45, .94),
  ease-in-out-quad: cubic-bezier(.455, .03, .515, .955),

  ease-in-cubic: cubic-bezier(.55, .085, .68, .53),
  ease-out-cubic: cubic-bezier(.25, .46, .45, .94),
  ease-in-out-cubic: cubic-bezier(.455, .03, .515, .955),

  ease-in-quart: cubic-bezier(.55, .085, .68, .53),
  ease-out-quart: cubic-bezier(.25, .46, .45, .94),
  ease-in-out-quart: cubic-bezier(.455, .03, .515, .955)
);

// Easings implementations
// Default timing function: 'ease'

[data-aos] {
  @each $key, $val in $aos-easing {
    body[data-aos-easing="#{$key}"] &,
    &[data-aos][data-aos-easing="#{$key}"] {
      // transition-timing-function: $val;
    }
  }
}