// sass-lint:disable no-vendor-prefixes, no-important

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  min-height: 100%;
  color: $color-grey;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

html {
  font-family: $font-stack-primary;
  font-weight: $font-weight-regular;
  color: $color-text;
  background-color: $color-black;
  min-height: 100%;
  height: 100%;
  font-size: $font-size-root-mobile;
  @include screen-tablet-landscape-and-bigger {
    font-size: $font-size-root;
  }

  &.homepage {
    // overflow: hidden;
    body {
      // overflow: hidden;
      *::selection {
        color: inherit;
        background-color: transparent;
      }
    }
  }
  &.no--scroll {
    overflow-y: hidden !important;
  }

// ——————————————————————————————————————————————————————

  &.menu-is-open {
    overflow-y: hidden !important;
    .wrapper {
      overflow-y: hidden;
      overflow-x: hidden;
      transform: scale(.9);
      opacity: 0;
      transition: transform .2s $ease-out-circ-special-two .2s, opacity .0s linear .4s;
      @include screen-tablet-landscape-and-bigger {
        transform: scale(1);
        opacity: 1;
      }
    }

    section.projects {
      opacity:0;
    }

  } 

  &.PT-general_out {
    overflow-y: hidden !important;
    .wrapper {
      overflow-y: hidden;
      overflow-x: hidden;
      transform: scale(.9);
      opacity: 0;
      transition: transform .2s $ease-out-circ-special-two .2s, opacity .0s linear .4s;
    }
  }
  
  &.PT-work_out {
    overflow-y: hidden !important;
    .wrapper {
      overflow-y: hidden;
      overflow-x: hidden;
      opacity: 0;
      transition: transform .2s $ease-out-circ-special-two .2s, opacity .0s linear .4s;
      .PT-work_selected {
        .figure-video, .figure-image {
          transform: scale(1.1);
        }
        figcaption {
          transform: scale(.8); 
        }
      }
      .figure-video, .figure-image {
        transform: scale(.8);
      }
      figcaption {
        transform: scale(.8); 
      }
    }
  }
}

.wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow-y: overlay;
  overflow-x: hidden;
  // overflow: scroll;
  opacity: 1;
  transform: scale(1);
  transition: transform .2s $ease-out-circ-special, opacity .0s linear;

  &::-webkit-scrollbar {
    width: 2px;
    @include screen-tablet-landscape-and-bigger {
      width: 5px;
    }
  }

  &::-webkit-scrollbar-track {
    background:  transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-grey-dark;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-grey-dark;
  }

}

body {
  @include fluid-type($font-size-body, $font-size-body * 0.75);
  line-height: $font-line-height-body;
  hyphens: none;
  word-break: normal;
}

strong {
  font-weight: $font-weight-regular;
}
