@font-face {
	font-family: 'Graphik';
	font-display: block;
	src: url('../fonts/graphik/graphik-regular-web.woff') format('woff');
	font-weight: $font-weight-regular;
}

@font-face {
	font-family: 'Haas';
	font-display: block;
	src: url('../fonts/haas/HaasGrotTextR-55Roman.woff') format('woff');
	font-weight: $font-weight-regular;
}


@font-face {
	font-family: 'HaasDisplay';
	font-display: block;
	src: url('../fonts/haas/NeueHaasGrotDispRound-55Roman') format('woff');
	font-weight: $font-weight-regular;
}
